export default {
    auth: {
        name: 'Name',
        lastname: 'Lastname',
        login: 'Log in',
        register: 'Register',
        free_register: 'Sign up free',
        private: 'Remember that our meetings are private and discreet',
        email_ph: 'Login with your registered email',
        forgot: 'I forgot my password',
        create_account: 'Create Account',
        next: 'Next',
        back: 'Back',
        gender: 'Gender',
        search_for: 'Search For',
        search_limits: 'Search Limit',
        email: 'Email',
        password: 'Password',
        password_confirmation: 'Password confirmation',
        restore_password: 'Restore password',
        restore_password_message: 'We will send a reset code to your recovery email to reset your password.',
        country: 'Country',
    },
    test: 'test on english',
    errors: {
        required_gender: 'Gender is required',
        required_search_for: 'Search for is required',
        required_search_limits: 'Search limits is required'
    }
}
