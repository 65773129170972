export function removeDoubleSlashFromUrl (url) {
    if (url == null) { return '' }
    return url.replace(/([^:]\/)\/+/g, "$1")
}

export function setScrollToElement (id_element) {
    var element = document.querySelector(id_element)
    const y = element.getBoundingClientRect().top + window.scrollY
    window.scroll({ top: y, behavior: 'smooth' })
}

export function getUrlVars () {
    var vars = {}
    var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
        vars[key] = value
    })
    return vars
}
