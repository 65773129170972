export default {
    publicUrl: document.head.querySelector('meta[name="public_url"]').getAttribute('content'),
    appName: document.head.querySelector('meta[name="app_name"]').getAttribute('content'),
    appBaseUrl: document.head.querySelector('meta[name="app_base_url"]').getAttribute('content'),
    appAdminBaseUrl: document.head.querySelector('meta[name="app_admin_base_url"]').getAttribute('content'),
    token: document.head.querySelector('meta[name="csrf-token"]').getAttribute('content'),
    hcaptcha: {
        sitekey: document.head.querySelector('meta[name="hcaptcha_sitekey"]').getAttribute('content')
    }
}
