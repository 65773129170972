export default {
    auth: {
        name: 'Nome',
        lastname: 'Sobrenome',
        login: 'Entrar',
        register: 'Cadastrar',
        free_register: 'Inscreva-se grátis',
        private: 'Lembre-se de que nossas reuniões são privadas e discretas',
        email_ph: 'Entre com seu email registrado',
        forgot: 'Esqueci minha senha',
        create_account: 'Criar Conta',
        next: 'Próximo',
        back: 'Voltar',
        gender: 'Gênero',
        search_for: 'Buscar Por',
        search_limits: 'Limite de Busca',
        email: 'Email',
        password: 'Senha',
        password_confirmation: 'Confirmação de Senha',
        restore_password: 'Restaurar Senha',
        restore_password_message: 'Enviaremos um código de redefinição para seu email de recuperação para redefinir sua senha.',
        country: 'País'
    },
    test: 'teste em inglês',
    errors: {
        required_gender: 'Gênero é obrigatório',
        required_search_for: 'Buscar por é obrigatório',
        required_search_limits: 'Limite de busca é obrigatório'
    }
};
