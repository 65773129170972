import Vue from 'vue'
import VueI18n from 'vue-i18n'
import messages from './lang/all'

Vue.use(VueI18n)

const locale = localStorage.getItem('language') || 'es'
localStorage.setItem('language', locale)


export default new VueI18n({
    locale: locale,
    fallbackLocale: 'en',
    messages: messages
})
