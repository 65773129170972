export default {
    auth: {
        name: 'Nombre',
        lastname: 'Apellido',
        login: 'Iniciar sesión',
        register: 'Registrarse',
        free_register: 'Regístrate gratis',
        private: 'Recuerda que nuestras reuniones son privadas y discretas',
        email_ph: 'Inicie sesión con su correo electrónico registrado',
        forgot: 'Olvidé mi contraseña',
        create_account: 'Crear una cuenta',
        next: 'Siguiente',
        back: 'Regresar',
        gender: 'Género',
        search_for: 'Buscar',
        search_limits: 'Límites',
        email: 'Correo',
        password: 'Contraseña',
        password_confirmation: 'Confirmación de contraseña',
        restore_password: 'Restablecer contraseña',
        restore_password_message: 'Enviaremos un código de restablecimiento a tu correo electrónico de recuperación para restablecer tu contraseña.',
        country: 'País'
    },
    test: 'prueba en ingles',
    errors: {
        required_gender: 'El campo Género es requerido',
        required_search_for: 'El campo Buscar es requerido',
        required_search_limits: 'El campo Límites es requerido'
    }
}
