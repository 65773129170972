import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
// Crevron down
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';

library.add(faChevronDown, faGlobe);

Vue.component('font-awesome-icon', FontAwesomeIcon)
